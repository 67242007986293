* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9; /* Light background */
  color: #333;
}

.app {
  text-align: center;
}

.app-header {
  background: linear-gradient(45deg, #007bff, #ff6f61); /* Blue to coral gradient */
  color: white;
  padding: 80px 20px;
}

.app-header h1 {
  font-size: 4rem;
}

.app-header p {
  font-size: 1.4rem;
  margin: 20px 0;
}

.cta-button {
  padding: 12px 24px;
  background-color: #ff6f61; /* Coral */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 8px;
}

.cta-button:hover {
  background-color: #ff4c36; /* Darker coral */
}

.features {
  background-color: #ffffff; /* Light blue */
  padding: 60px 20px;
}

.features h2 {
  color: #007bff;
  font-size: 2.5rem;
}

.integrations {
  background-color: #ffffff; /* Light yellow */
  padding: 60px 20px;
  color: #333;
}


.integrations {
  background-color: #ffffff; /* Silver */
  padding: 60px 20px;
  color: #333;
}

.integrations h2 {
  color: #007bff;
  font-size: 2.5rem;
}

.contact {
  background-color: #333;
  color: white;
  padding: 60px 20px;
}

.contact h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact input {
  margin: 10px 0;
  padding: 10px;
  width: 250px;
  border: none;
  border-radius: 5px;
}

.contact button {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.app-footer {
  background-color: #000;
  color: white;
  padding: 20px;
}

.app-footer p {
  font-size: 1rem;
}